.cookbook-canvas {
  height: 100%;
  width: 100%;
}

.cookbook-content {
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-rows: auto 1fr;
}

/*          IF NO CONTENT         */

.tabcookbook-no-recipes-default {
  margin-bottom: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.tabcookbook-no-recipes-default h2 {
  color: #000;
  text-align: center;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin: 0;
}

.tabcookbook-no-recipes-default img {
  max-width: 220px;
  max-width: 220px;
  flex-shrink: 0;
}

/*          CONTENT         */
.tabcookbook-show-liked-recipes {
  min-width: 100%;
  height: 100%;
}

.tabcookbook-show-liked-recipes .sub-header-title {
  color: #000;
  font-family: EB Garamond;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;

  margin: 0;
  margin-bottom: 10px;
}
