.login-sign-form-section {
  width: 100%;
}
.login-sign-form-section > * {
  margin: 0;
}

.login-sign-form-section input {
  display: flex;
  padding: 10px 22px 10px 22px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 50px;
  border: 1px solid rgba(171, 171, 171, 0.3);
  background: #fff;

  width: 100%;
  box-sizing: border-box;
}

.login-sign-form-section input::placeholder {
  color: #ababab;
}

.login-sign-form-section input:focus {
  color: black;
}

.login-sign-form-section h1 {
  color: #000;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-top: 18px;
  margin-bottom: 15px;
}

.login-sign-form-section h1 span {
  /*  '*'       */
  color: #dc2100;
  font-family: Work Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.login-button {
  display: flex;
  width: 100%;
  padding: 10px 0px 10px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 50px;
  border: 1px solid #000;
  background: #000;

  color: #fff;
  text-align: center;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  cursor: pointer;

  margin-top: 40px;
}

.login-button:hover {
  background-color: #f7f9f7;

  color: black;
  border: 1px solid #000;

  background: #fff;

  transition: 0.3s;
}

.error-message {
  color: #dc2100;
  font-family: Work Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin: 0;
  margin-top: 10px;
}

@media screen and (max-width: 980px) {
  .login-sign-form-section h1 {
    font-size: 16px;
  }

  .login-sign-form-section input {
    font-size: 16px;
  }

  .login-button {
    font-size: 16px;
  }
}

@media screen and (max-width: 500px) {
  .login-sign-form-section h1 {
    font-size: 10px;
    margin-top: 9px;
    margin-bottom: 7px;
  }

  .login-sign-form-section input {
    font-size: 10px;
    padding: 5px 11px 5px 11px;
  }

  .login-button {
    margin-top: 20px;
    font-size: 10px;
    padding: 5px 0px;
  }
}
