/*    CARDS   */

.calendar-upcoming-recipe-card {
  display: grid;
  grid-template-columns: 100px 215px;
  justify-content: flex-start;
  align-items: center;

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  width: 335px;
  height: 100px;
  gap: 10px;
}

/*    CARDS IMAGE  */
.calendar-upcoming-recipe-card .calendar-upcoming-recipe-image {
  border-radius: 10px 0px 0px 10px;

  height: 100%;

  background-size: cover;
  background-position: center;

  position: relative;
}
.calendar-upcoming-recipe-card
  .calendar-upcoming-recipe-image
  .image-hover-bttn {
  display: none;

  transition: 0.5s ease-in;
}
.calendar-upcoming-recipe-card
  .calendar-upcoming-recipe-image:hover
  .image-hover-bttn {
  cursor: pointer;
  display: block;
  transition: 0.5s ease-in;

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  background: rgba(0, 0, 0, 0.4);

  border: none;

  p {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;

    color: #ffffff;

    margin: 0;
  }
}

/*    CARDS CONTENT   */
.calendar-upcoming-recipe-content {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 15px;
}
.calendar-upcoming-recipe-content h1 {
  font-family: "EB Garamond";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #000000;

  margin: 0;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.calendar-upcoming-recipe-subcontent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  gap: 50px;
}
.calendar-upcoming-recipe-subcontent p {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  color: #000000;

  margin: 0;
}

.calendar-upcoming-recipe-subcontent p:last-child {
  display: flex;
  justify-content: flex-end;
}
.calendar-upcoming-recipe-content .small-labels {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.calendar-upcoming-recipe-content .small-labels p {
  margin: 0;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  color: #000000;
}

.calendar-upcoming-recipe-content .small-labels span {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #000000;

  margin: 0;
}

.calendar-upcoming-recipe-content .small-labels .calendar-card-minutes {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  margin: 0;

  border-radius: 7px;
  padding: 5px 8px;
}
