.event-search-options {
  position: absolute;
  z-index: 999;

  background-color: #fff;

  box-sizing: border-box;
  border: 1px solid #ababab;
  margin-top: -1px;
  margin-left: -1px;

  width: -webkit-fill-available;
  margin-right: 21px;
}

.unordered-search-options-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.unordered-search-options-list > li {
  padding: 5px;
  cursor: pointer;

  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: gray;
}

.unordered-search-options-list > li:hover {
  background-color: #ffe6d8;
}
