/* PasswordInput.css */

.password-container {
  position: relative;
  margin-bottom: 20px;
}

.password-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.requirements-box {
  display: none;
}

.password-input:focus + .requirements-box {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 95.5%;
  padding: 10px;
  font-size: 14px;

  background-color: #f7f9f7;
  border: 2px solid rgba(0, 0, 0, 0.1);
}

.checklist p,
ul {
  color: #000;
  font-family: Work Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.requirements-box ul {
  list-style: none; /* Remove default bullet points */
  padding-left: 0; /* Remove default left padding */
}

.checklist ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-left: 20px;
  margin-bottom: 10px;
}

.checklist ul li img {
  margin-right: 5px;
  height: 15px;
}

.signup-input-label h1 {
  display: flex;
  align-items: center;
}

.error-input {
  color: #eb4335;
  font-family: Work Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  margin-left: 10px;
}

.error-input-span {
  color: #eb4335;
  font-family: Work Sans;
  font-size: 14px;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.non-error-text {
  display: none;
}

@media screen and (max-width: 500px) {
  .signup-input-label h1 {
    font-size: 10px;
    margin-top: 9px;
    margin-bottom: 7px;
  }

  .password-container h1 {
    font-size: 10px;
    margin-top: 9px;
    margin-bottom: 7px;
  }
  .password-container {
    margin-bottom: 10px;
  }

  .error-input {
    font-size: 10px;
  }

  .login-sign-form-section h1 span {
    font-size: 10px;
  }
}
