.heart-icon {
  position: absolute;

  width: 50px;
  height: 50px;

  background: url(https://cssanimation.rocks/images/posts/steps/heart.png)
    no-repeat;
  background-position: 0 0;
  cursor: pointer;
  transition: background-position 1s steps(28);
  transition-duration: 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;

  &.heart-btn-is-active {
    transition-duration: 0.8s;
    background-position: -1400px 0;
  }
}

.heart-btn-stage {
  position: relative;

  /*top: 50% for search hearts  top: 2% for likes hearts in cookbook*/
  left: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
